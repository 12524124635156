import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css/animate.min.css";
import "assets/sass/index.scss";

import Routing from "./router";

const App = () => {
    return (
        <>
            <Routing />
        </>
    );
};

export default App;
