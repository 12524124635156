import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const Landing = lazy(() => import("./pages/Landing"));
const Product = lazy(() => import("./pages/Product"));
const Member = lazy(() => import("./pages/Member"));
// const Inquiry = lazy(() => import("./pages/Inquiry"));
const NotFound = lazy(() => import("./common/components/NotFound"));

const Routing = () => {
    return (
        <Router>
            <Suspense fallback={<div className="loading" />}>
                <Routes>
                    <Route exact path="/" element={<Landing />} />
                    <Route path="/product/*" element={<Product />} />
                    <Route path="/member" element={<Member />} />
                    {/* <Route path="/inquiry" element={<Inquiry />} /> */}

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
        </Router>
    );
};

export default Routing;
