import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "./translationEn";
import translationKo from "./translationKo";

// const language = window.navigator.language;

const resources = {
    en: {
        // 영어
        translation: translationEn,
    },
    ko: {
        // 한국어
        translation: translationKo,
    },
};

i18n.use(initReactI18next).init({
    resources: resources,
    // lng: language.includes("ko") ? "ko" : "en",
    lng: "en",
    fallbackLng: "en",

    debug: true,
    keySeparator: ".", // we do not use keys in form messages.welcome
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});

export default i18n;
